import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ConfirmationService, Person, UserService} from '@taures/angular-commons';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {AssistanceResult, ConsultantOfDialogComponent} from '../consultant-of-dialog/consultant-of-dialog.component';
import {CustomerLink, SoftfairService} from '../../services/softfair.service';
import {PersonManagementService} from '../../services/person-management.service';
import {MatDialog} from '@angular/material/dialog';
import log from 'loglevel';

@Component({
  selector: 'app-softfair-dispatch',
  templateUrl: './softfair-dispatch.component.html',
  styleUrls: ['./softfair-dispatch.component.scss'],
  host: {class: 'flex flex-column flex-grow-1'}
})
export class SoftfairDispatchComponent implements OnInit {
  loading: boolean = true
  customer: Person;
  assistanceResult: AssistanceResult;
  message: string;

  constructor(private activatedRoute: ActivatedRoute,
              private softfairService: SoftfairService,
              private userService: UserService,
              private confirmationService: ConfirmationService,
              private personManagementService: PersonManagementService,
              private changeDetector: ChangeDetectorRef,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.load();
  }

  private load(force: boolean = false) {
    this.message = 'Daten werden übertragen'
    combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.data,
      this.checkIfCurrentIsAssistant()
    ]).pipe(switchMap(([{productKey}, {customer}, assistance]) => {
      if (!assistance) {
        this.closeWindow()
      }
      Object.assign(this, {loading: true, productKey, customer})
      this.changeDetector.markForCheck();
      const onBehalfOf = assistance.self ? null : assistance.superior;
      return this.softfairService.getCustomerLink(productKey, this.customer.personManagementId, onBehalfOf, force)
    }), catchError((error, cause) => {
      this.message = null
      log.warn('Get customerLink failed', error, cause);
      return of({...error.error, errorStatus: error.status} as CustomerLink);
    })).subscribe((link) => {
      log.debug('customerLink fetched', link)
      const {success, message, url, lockedBy, errorStatus} = link
      this.changeDetector.markForCheck();
      if (!success) {
        Object.assign(this, {loading: false, message: null})
        this.changeDetector.markForCheck();
        if (errorStatus === 423 || lockedBy) return this.handleLock(link)
        this.confirmationService.showPopup({
          message: message,
          confirmationType: 'danger',
          confirmButtonText: 'OK',
          showCancelButton: false
        }).afterClosed().subscribe(() => {
          this.closeWindow()
        })
      } else {
        Object.assign(this, {loading: true, message: 'Du wirst nun weitergeleitet'})
        this.changeDetector.markForCheck();
        window.location.replace(url);
      }
    })
  }

  checkIfCurrentIsAssistant(): Observable<AssistanceResult> {
    if (this.assistanceResult) return of(this.assistanceResult)
    return this.userService.getCurrentUser().pipe(
      switchMap(user => this.personManagementService.isAssistantOf(user.personManagementId).pipe(
        map(superiors => ({user, superiors}))
      )),
      switchMap(({user: currentUser, superiors: consultants}) => {
        this.loading = false;
        this.changeDetector.markForCheck()
        if (consultants && consultants.length) {
          return this.dialog.open(ConsultantOfDialogComponent, {
            data: {currentUser, consultants},
            width: '336px',
            maxHeight: '80vh'
          }).afterClosed() as Observable<AssistanceResult>;
        }
        return of({self: true} as AssistanceResult);
      }),
      tap(ar => (this.assistanceResult = ar))
    );
  }

  private handleLock(link: CustomerLink) {
    this.confirmationService.showPopup({
      message: link.bypass ? 'Du bearbeitest den Kunden möglicherweise bereits in einem anderen Fenster!\nUm Datenverluste zu vermeiden, nutze bitte weiterhin das geöffnete Fenster oder melde dich dort aus dem KonzeptButler ab.'
        : `Zurzeit wird die Kundin/der Kunde von ${link.lockedBy?.firstName} ${link.lockedBy?.lastName} (${link.lockedBy?.email}) im KonzeptButler bearbeitet. Der Aufruf ist daher nicht möglich.`,
      confirmationType: 'warning',
      confirmButtonText: link.bypass ? 'Trotzdem fortfahren' : 'OK',
      showCancelButton: link.bypass
    }).afterClosed().subscribe(confirmed => {
      if (link.bypass && confirmed) {
        this.load(true)
      } else {
        this.closeWindow()
      }
    });
  }

  closeWindow() {
    if (window.opener != null) window.close()
    window.location.href = (this.customer?.anrede === 'Firma' ? '/businesses/' : '/customers/') + this.customer?.id
  }
}
